<template>
  <div class="wrapper">
    <div>
      <CToaster :autohide="3000">
        <template v-for="info in infoList">
          <CToast
            :key="info.message"
            :show="true"
            :header="info.header"
            :color="info.color"
          >
            {{ info.message }}.
          </CToast>
        </template>
      </CToaster>
      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardHeader>
              <strong> SubCompany </strong> Information
            </CCardHeader>
            <CCardBody>
              <CForm>
                <CInput
                  label="Name"
                  v-model="$v.obj.name.$model"
                  :isValid="checkIfValid('name')"
                  invalidFeedback="This is a required field"
                  horizontal
                />
                <CInput label="RegNo" horizontal v-model="obj.regNo" />
                <CInput label="Tagline" horizontal v-model="obj.tagLine" />
                <CRow form class="form-group">
                  <CCol tag="label" sm="3" class="col-form-label">
                    Address
                  </CCol>
                  <CCol sm="9">
                    <CTextarea
                      placeholder="Address..."
                      rows="5"
                      v-model="obj.address"
                    />
                  </CCol>
                </CRow>
                <CInput label="Phone" v-model="obj.phone" horizontal />
                <CRow form class="form-group">
                  <CCol tag="label" sm="3" class="col-form-label">
                    Company Logo
                  </CCol>
                  <CCol sm="9">
                    <CImg :src="getImageUrl()" class="mb-2" thumbnail></CImg>
                  </CCol>
                </CRow>
                <CRow form class="form-group">
                  <CCol tag="label" sm="3" class="col-form-label"> </CCol>
                  <CCol sm="9">
                    <WidgetsUploadImage
                      :logoUrl="logoUrl"
                      @uploaded="uploaded"
                    />
                  </CCol>
                </CRow>

                <CRow form class="form-group" v-if="obj.id">
                  <CCol tag="label" sm="3" class="col-form-label">
                    Bank Accounts
                  </CCol>
                  <CCol sm="9">
                    <CCard>
                      <CCardBody>
                        <CDataTable
                          :items="obj.bankAccounts"
                          :fields="bankAccountFields"
                        >
                          <template #show_details="{ item }">
                            <td class="py-2">
                              <CButton
                                size="sm"
                                color="info"
                                class="ml-1"
                                @click="onEditBankAccount(item)"
                              >
                                Edit
                              </CButton>
                              <CButton
                                size="sm"
                                color="danger"
                                class="ml-1"
                                @click="showDeleteBankAccountConfirmation(item)"
                              >
                                Delete
                              </CButton>
                            </td>

                            <!-- <td class="py-2">
                              <CButton
                                color="primary"
                                variant="outline"
                                square
                                size="sm"
                              >
                                Remove
                              </CButton>
                            </td> -->
                          </template>
                        </CDataTable>

                        <!-- <CInput
                          label="Name"
                          v-model="newBankAccount.name"
                          horizontal
                        /> -->
                        <!-- <CInput
                          label="Short Name"
                          v-model="obj.shortName"
                         
                          horizontal
                        /> -->
                      </CCardBody>
                      <CCardFooter>
                        <CButton
                          color="primary"
                          variant="outline"
                          square
                          size="sm"
                          @click="addBankAccountPopup()"
                        >
                          Add
                        </CButton>
                      </CCardFooter>
                    </CCard>
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
            <CCardFooter>
              <CButton
                type="submit"
                size="sm"
                color="primary"
                :disabled="!isValid"
                @click="onSubmit"
              >
                <CIcon name="cil-check-circle" /> Submit
              </CButton>

              <CButton class="ml-1" color="secondary" @click="cancel">
                Cancel
              </CButton>
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
    </div>
    <div>
      <CModal
        title="Add/Edit Bank Account"
        :show.sync="addNewBankAccountPopupState"
        size="xl"
        @update:show="onAddNewBankConfirmation"
      >
        <CForm>
          <CInput label="Name" v-model="newBankAccount.name" horizontal />
          <CRow form class="form-group">
            <CCol tag="label" sm="3" class="col-form-label"> Address </CCol>
            <CCol sm="9">
              <CTextarea
                placeholder="Address..."
                rows="5"
                v-model="newBankAccount.address"
              />
            </CCol>
          </CRow>

          <CInput
            label="Account Name"
            v-model="newBankAccount.accountName"
            horizontal
          />
          <CInput
            label="Account No"
            v-model="newBankAccount.accountNo"
            horizontal
          />
          <CInput
            label="Account Type"
            v-model="newBankAccount.accountType"
            horizontal
          />
        </CForm>
      </CModal>
    </div>
    <div>
      <CModal
        title="Confirm Delete"
        color="warning"
        :show.sync="warningDeleteBankAccountModal"
        @update:show="onDeleteBankAccountConfirmation"
      >
        Are you sure you want to delete this
        {{ itemBankAccountToDelete.name }} ?
      </CModal>
    </div>
  </div>
</template>

<script>
import SubCompanyApi from "../../lib/subCompanyApi";
import { required, minLength } from "vuelidate/lib/validators";
import WidgetsUploadImage from "../widgets/WidgetsUploadImage";
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "SubCompany",
  components: {
    WidgetsUploadImage,
  },
  data: () => {
    return {
      warningDeleteBankAccountModal: false,
      itemBankAccountToDelete: {},

      addNewBankAccountPopupState: false,
      newBankAccount: {
        id: null,
        name: "",
        address: "",
        accountName: "",
        accountNo: "",
        accountType: "",
      },
      bankAccountFields: [
        { key: "name" },
        { key: "address" },
        { key: "accountName" },
        { key: "accountNo" },
        { key: "accountType" },
        // { key: "shortName" },
        {
          key: "show_details",
          label: "",
          _style: "min-width:150px; width:2%",
          sorter: false,
          filter: false,
        },
      ],
      infoList: [],
      api: new SubCompanyApi(),
      obj: {
        name: "",
      },
      submitted: false,
    };
  },
  mounted() {
    var self = this;
    this.obj = this.getEmptyObj();

    self.resetObj();
  },
  validations: {
    obj: {
      name: {
        required,
        minLength: minLength(2),
      },
    },
  },

  computed: {
    formString() {
      return JSON.stringify(this.obj, null, 4);
    },
    isValid() {
      return !this.$v.obj.$invalid;
    },
    isDirty() {
      return this.$v.obj.$anyDirty;
    },
  },
  methods: {
    onEditBankAccount(item) {
      this.newBankAccount = item;
      this.addNewBankAccountPopupState = true;
    },

    onDeleteBankAccountConfirmation(status, evt, accept) {
      var self = this;
      if (accept) {
        this.api
          .removeBankAccount(this.itemBankAccountToDelete.id)
          .then((response) => {
            self.resetObj();
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }
      self.itemBankAccountToDelete = {};
    },

    showDeleteBankAccountConfirmation(item) {
      var self = this;
      self.itemBankAccountToDelete = item;
      self.warningDeleteBankAccountModal = true;
    },

    onAddNewBankConfirmation(status, evt, accept) {
      var self = this;
      if (accept) {
        console.log(self.newBankAccount);

        if (self.newBankAccount.id) {
          this.api
            .updateBankAccount(self.obj.id, self.newBankAccount)
            .then((response) => {
              self.toast("Success", "Bank Account Updated", "success");

              self.resetObj();
            })
            .catch(({ data }) => {
              self.toast("Error", helper.getErrorMessage(data), "danger");
            });
       
        } else {
          // alert('asds');
          console.log(self.newBankAccount);

          self.newBankAccount.id = uuidv4();
          self.newBankAccount.subCompanyId = self.obj.id;

          this.api
            .addBankAccount(self.obj.id, self.newBankAccount)
            .then((response) => {
              self.resetObj();
            })
            .catch(({ data }) => {
              self.toast("Error", helper.getErrorMessage(data), "danger");
            });
        }
      }
    },

    resetNewBankAccount() {
      this.newBankAccount.id = null;
      this.newBankAccount.name = "";
      this.newBankAccount.address = "";
      this.newBankAccount.accountName = "";
      this.newBankAccount.accountNo = "";
      this.newBankAccount.accountType = "";
      this.newBankAccount.subCompanyId = null;
    },
    addBankAccountPopup() {
      this.resetNewBankAccount();
      this.addNewBankAccountPopupState = true;
    },
    // addBankAccount() {
    //   var self = this;
    //   this.api
    //     .addModel(self.obj.id, self.newBankAccount)
    //     .then((response) => {
    //       self.newBankAccount.name = "";
    //       self.resetObj();
    //     })
    //     .catch(({ data }) => {
    //       self.toast("Error", helper.getErrorMessage(data), "danger");
    //     });
    // },
    // removeBankAccount(item) {
    //   console.log(item);
    //   var self = this;
    //   this.api
    //     .removeBankAccount(item)
    //     .then((response) => {
    //       self.resetObj();
    //     })
    //     .catch(({ data }) => {
    //       self.toast("Error", helper.getErrorMessage(data), "danger");
    //     });
    // },

    removeTrailingSlash(str) {
      if (str.endsWith("/")) {
        return str.slice(0, -1);
      }
      return str;
    },
    getImageUrl() {
      if (this.obj) {
        var url = this.removeTrailingSlash(apiUrl) + this.obj.logoUrl;
        console.log(url);
        return url;
      }
      return "";
    },

    uploaded(data) {
      this.uploadedFiles = data.uploadedFiles;
      this.obj.logoUrl = this.uploadedFiles[0].url;
    },

    checkIfValid(fieldName) {
      const field = this.$v.obj[fieldName];
      return !(field.$invalid || field.$model === "");
    },
    onSubmit() {
      var self = this;
      if (!self.obj.id) {
        this.api
          .create(self.obj)
          .then((response) => {
            self.$router.push({ path: "/admins/SubCompanyList" });
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      } else {
        this.api
          .update(self.obj)
          .then((response) => {
            self.$router.push({ path: "/admins/SubCompanyList" });
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }
    },
    toast(header, message, color) {
      var self = this;
      self.infoList.push({
        header: header,
        message: message,
        color: color,
      });
    },
    resetObj() {
      var self = this;
      if (self.$route.params.id) {
        this.api
          .get(self.$route.params.id)
          .then((response) => {
            self.obj = response.result;
            console.log(self.obj);
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      } else {
        self.obj = self.getEmptyObj();
      }
    },

    getEmptyObj() {
      return {
        name: "",
        address: "",
        country: "",
        phone: "",
        website: "",
        contactPersonName: "",
        contactPersonPhone: "",
        contactPersonEmail: "",
      };
    },
    cancel() {
      this.$router.push({ path: "/admins/SubCompanyList" });
    },
  },
};
</script>
